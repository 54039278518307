// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:100,300,400,600);

// Variables
@import 'variables';

// Bootstrap
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap';

// Tom

@media (min-width: 1400px) {
  .container {
    width: 1370px;
  }
}

html {
  font-size: 16px;

  height: 100%;
}

body {
  display: flex;
  flex-direction: column;

  font-family: 'Roboto', sans-serif;
  font-size: 1rem;

  min-height: 100%;
  margin: 0;

  text-align: center;

  color: $text-color;
  background-color: $color-white;
}

::selection {
  color: $color-white;
  background: $brand-color;
}

::-moz-selection {
  color: $color-white;
  background: $brand-color;
}

a {
  text-decoration: none;

  color: $text-color;
}

a:hover,
a:focus {
  text-decoration: none;
}

a.cyt-btn {
  display: inline-block;

  font-size: .875rem;
  font-weight: 300;
  line-height: 40px;

  height: 40px;
  padding: 0 30px;

  text-align: center;

  color: $color-white;
  border-radius: 20px;


  @media ($medium) {
    font-size: 1.25rem;
    line-height: 60px;

    height: 60px;
    padding: 0 40px;

    border-radius: 30px;
  }

  @media ($large) {
  }

  @media ($xlarge) {
  }

  @media ($xxlarge) {
  }
}

.img-responsive {
  margin: 0 auto;
}

.bg-green {
  background: $brand-color;
}

.color-green {
  color: $brand-color;
}

.bg-blue {
  background: $color-blue;
}

.content {
  flex: 1 0 auto;
}

section.container {
  margin-top: 30px;
  margin-bottom: 30px;

  @media ($medium) {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

.spacer {
  height: 1px;

  background: lighten($color-black, 90);
}

.mobile-spacer {
  height: 50px;

  @media ($medium) {
    display: none;
  }
}

/*
 ██████  ███████ ███    ██ ███████ ██████   █████  ██
██       ██      ████   ██ ██      ██   ██ ██   ██ ██
██   ███ █████   ██ ██  ██ █████   ██████  ███████ ██
██    ██ ██      ██  ██ ██ ██      ██   ██ ██   ██ ██
 ██████  ███████ ██   ████ ███████ ██   ██ ██   ██ ███████
*/


h1,
h2 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.3;

  margin: 0;
  padding: 0 0 1.5rem;

  @media ($medium) {
    font-size: 2.5rem;
    line-height: 1.2;

    padding: 0 0 3rem;

    letter-spacing: -.1rem;
  }

  span {
    color: $color-red;
  }
}

.text-container h2 {
  font-size: 1rem;

  padding: 0 0 1rem;

  @media ($medium) {
    font-size: 1.75rem;

    padding: 0 0 1.75rem;

    letter-spacing: -.05rem;
  }
}

p {
  font-size: .875rem;
  font-weight: 300;
  line-height: 1.75;

  margin-bottom: 1.5rem;

  @media ($medium) {
    margin-bottom: 1rem;
  }

  @media ($large) {
    font-size: 1.125rem;

    margin-bottom: 1.25rem;
  }

  @media ($xlarge) {
    font-size: 1.25rem;

    margin-bottom: 1.5rem;
  }

  @media ($xxlarge) {
    margin-bottom: 3rem;
  }
}

/*
██   ██ ███████  █████  ██████  ███████ ██████
██   ██ ██      ██   ██ ██   ██ ██      ██   ██
███████ █████   ███████ ██   ██ █████   ██████
██   ██ ██      ██   ██ ██   ██ ██      ██   ██
██   ██ ███████ ██   ██ ██████  ███████ ██   ██
*/



header {
  width: 100%;
  height: 66px;

  position: fixed;
  z-index: 999;

  text-align: left;

  background-color: $color-white;
  box-shadow: $dark-shadow;

  @media ($medium) {
    height: 80px;

    background-color: rgba(255,255,255,.95);
  }

  nav {
    .logo {
      float: left;

      width: 120px;
      height: 46px;
      margin: 10px;

      text-indent: -9999px;

      background-image: url('/img/logo.svg');
      background-size: 120px 46px;

      @media ($medium) {
        width: 130px;
        height: 50px;
        margin: 15px;

        background-size: 130px 50px;
      }
    }

    input {
      display: none;

      &:checked ~ .menu {
        display: block;
      }
    }
  }

  label {
    margin-bottom: 0;
  }
}


.menu {
  display: none;
  clear: both;

  height: 100vh;
  padding: 0;

  list-style-type: none;

  background: $color-white;

  @media ($medium) {
    display: block;
    clear: none;

    height: 80px;
    margin: 0;
    padding: 0;

    position: static;

    background: none;
  }


  ul {
    display: flex;
    flex-direction: column;

    height: calc(100vh - 132px);
    margin: 0;
    padding: 0;

    align-items: center;
    justify-content: center;

    @media ($medium) {
      display: block;

      height: 80px;
    }

    li {
      display: block;

      text-align: center;

      @media ($medium) {
        display: inline-block;
      }

      a {
        display: block;

        font-size: 2em;
        font-weight: 300;

        text-align: center;

        &:hover + .hidden,
        .hidden:hover {
          display: block;
        }

        @media ($medium) {
          font-size: 1rem;
          font-weight: 300;
          line-height: 80px;

          height: 80px;
          padding: 0 10px;
        }
      }
    }
  }
}

.show-menu,
.phone {
  display: block;
  float: right;

  i {
    font-size: 2rem;

    padding: 17px;
  }

  @media ($medium) {
    display: none;
  }
}

.phone i {
  font-size: 1.75rem;

  padding: 19px 0 19px 15px;
}

.info {
  display: none;

  @media ($medium) {
    display: block;
    float: right;

    font-weight: 300;
    line-height: 80px;

    height: 80px;
    margin-right: 30px;
  }

  i {
    font-size: 1.125rem;

    margin: 0 5px 0 0;

    position: relative;
    top: 4px;
  }

  a {
    margin-left: 30px;

    &.btn-head {
      display: inline-block;

      line-height: 40px;

      height: 40px;
      padding: 0 25px;

      color: #fff;
      border-radius: 20px;
    }
  }

  a.logout {
    margin-left: 20px;
  }
}

@media (max-width: 1100px) {
  .info a:last-of-type {
    display: none;
  }
}

@media (max-width: 1000px) {
  .info a:first-of-type {
    display: none;
  }
}

@media (max-width: 900px) {
  .info {
    display: none;
  }
}


/*
██   ██  ██████  ███    ███ ███████
██   ██ ██    ██ ████  ████ ██
███████ ██    ██ ██ ████ ██ █████
██   ██ ██    ██ ██  ██  ██ ██
██   ██  ██████  ██      ██ ███████
*/




.home-image {
  height: 350px;

  color: $color-white;
  background: url('../img/home-image.jpg');
  background-position: top center;
  background-size: cover;;

  @media ($medium) {
    height: 750px;
  }
}

.home-container {
  margin-top: -284px;

  color: #fff;

  @media ($medium) {
    margin-top: -670px;
  }

  h1 {
    font-size: 1.5rem;
    line-height: 1.3;

    margin: 30px 0 10px;
    padding: 0;

    @media ($medium) {
      font-size: 3.5rem;
      line-height: 1.2;

      margin: 80px 0 10px;
      padding: 0;

      letter-spacing: -.1rem;
    }
  }

  p {
    line-height: 1.5;

    margin: 0;
  }

  a {
    margin: 20px 0 30px;

    @media ($medium) {
      margin: 40px 0 80px;
    }
  }
}

.screens {
  padding: 0 0 20px;

  text-align: center;

  img {
    max-width: 90%;
  }

  @media ($medium) {
  }
}


.icons div {
  p {
    font-size: 1.25rem;
    line-height: 1.5;

    margin: 1rem 0 2rem;

    @media ($xlarge) {
      font-size: 1.5rem;

      margin: 2rem 0 3rem;
    }
  }

  img {
    width: 80%;

    @media ($xlarge) {
      width: 70%;
    }
  }
}

.checked {
  margin: 0 0 1.5rem;
  padding: 0;

  list-style-type: none;

  @media ($xxlarge) {
    margin: 0 0 3rem;
  }

  li {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.5;

    margin: .5rem 0;

    @media ($xlarge) {
      font-size: 1.5rem;

      margin: .75rem 0;
    }

    i {
      font-size: 1.5rem;

      margin-left: .25rem;

      vertical-align: bottom;

      color: $color-blue;

      @media ($xlarge) {
        font-size: 3rem;
      }
    }
  }
}


/*
████████ ███████ ███    ███ ██████  ██       █████  ████████ ███████ ███████
   ██    ██      ████  ████ ██   ██ ██      ██   ██    ██    ██      ██
   ██    █████   ██ ████ ██ ██████  ██      ███████    ██    █████   ███████
   ██    ██      ██  ██  ██ ██      ██      ██   ██    ██    ██           ██
   ██    ███████ ██      ██ ██      ███████ ██   ██    ██    ███████ ███████
*/



section.templates {
  @media ($small) {
    padding: 0 30px;
  }

  @media ($large) {
    padding: 0 40px;
  }

  span {
    display: inline-block;

    font-size: 3rem;
    font-weight: 300;

    margin-bottom: 2rem;
  }

  a {
    display: block;

    font-size: .875rem;
    font-weight: 300;
    line-height: 40px;

    width: 80%;
    height: 40px;
    margin: 0 0 10px;

    text-align: center;

    color: $color-white;
    border-radius: 20px;
    background: $color-blue;

    &:last-of-type {
      background: $brand-color;
    }

    @media ($medium) {
      font-size: 1.25rem;
      line-height: 60px;

      height: 60px;

      border-radius: 30px;
    }

    @media ($large) {
    }

    @media ($xlarge) {
    }

    @media ($xxlarge) {
    }
  }

  > div {
    padding: 0 2% 2%;

    @media ($large) {
      padding: 0 1.5% 3%;
    }
  }



  .single-template {
    display: inline-block;

    position: relative;

    border: 1px solid #f2f2f2;
    box-shadow: 0 0 .5555555556em rgba(50,51,53,.06);

    &:hover .layover {
      opacity: 1;
    }
  }

  .layover {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    -webkit-transition: .3s;
       -moz-transition: .3s;
         -o-transition: .3s;
            transition: .3s;
    text-align: center;

    opacity: 0;
    background: rgba(255,255,255,.9);

    align-items: center;
    justify-content: center;
  }
}


/*
███████  ██████   ██████  ████████ ███████ ██████
██      ██    ██ ██    ██    ██    ██      ██   ██
█████   ██    ██ ██    ██    ██    █████   ██████
██      ██    ██ ██    ██    ██    ██      ██   ██
██       ██████   ██████     ██    ███████ ██   ██
*/




footer {
  clear: both;

  font-size: 1.125rem;
  font-weight: 300;

  padding: 20px 10px;

  background-color: $color-palegrey;

  @media ($medium) {
    display: flex;

    font-size: .875rem;

    justify-content: space-between;
  }

  div {
    text-align: center;

    a {
      display: block;

      padding: 3px 10px;

      @media ($medium) {
        display: inline-block;

        padding: 0 10px;
      }
    }

    &:last-of-type {
      font-size: .875rem;

      padding: 20px 0 0;

      span {
        display: block;

        @media ($medium) {
          display: inline-block;
        }
      }

      @media ($medium) {
        padding: 0 10px 0 0;
      }
    }
  }
}

/*
 ██████  ██████  ███    ██ ███████ ██  ██████  ██    ██ ██████   █████  ████████  ██████  ██████
██      ██    ██ ████   ██ ██      ██ ██       ██    ██ ██   ██ ██   ██    ██    ██    ██ ██   ██
██      ██    ██ ██ ██  ██ █████   ██ ██   ███ ██    ██ ██████  ███████    ██    ██    ██ ██████
██      ██    ██ ██  ██ ██ ██      ██ ██    ██ ██    ██ ██   ██ ██   ██    ██    ██    ██ ██   ██
 ██████  ██████  ██   ████ ██      ██  ██████   ██████  ██   ██ ██   ██    ██     ██████  ██   ██
*/



.facts {
  font-weight: 300;

  margin: 20px 0;

  ul {
    overflow: hidden;

    margin: 0;
    padding: 0;

    list-style: none;

    text-align: center;

    border: 1px solid lighten($color-black, 90);
    border-radius: 20px;
    background: $color-white;

    li {
      padding: 20px;

      border-bottom: solid 1px lighten($color-black, 90);

      &:first-of-type,
      &:last-of-type {
        font-size: 1.5rem;

        background: lighten($color-black, 98);
      }

      &:last-of-type {
        border-bottom: none;
      }
    }

    i {
      font-size: 2rem;

      margin-left: .25rem;

      vertical-align: bottom;
    }

    i.negative {
      vertical-align: middle;

      color: $color-red;
    }

    i.positive {
      color: $brand-color;
    }
  }
}

/*
██████  ██████  ███████ ██    ██ ██ ███████ ██     ██
██   ██ ██   ██ ██      ██    ██ ██ ██      ██     ██
██████  ██████  █████   ██    ██ ██ █████   ██  █  ██
██      ██   ██ ██       ██  ██  ██ ██      ██ ███ ██
██      ██   ██ ███████   ████   ██ ███████  ███ ███
*/



.myshadow {
  position: relative;
  z-index: 998;

  box-shadow: $dark-shadow;
}

.switcher {
  display: flex;

  height: 90px;

  z-index: 998;

  color: $text-color;
  background-color: $color-white;

  align-items: middle;
  flex-flow: row nowrap;
  justify-content: center;

  a {
    display: block;

    color: $text-color;
  }

  div {
    font-size: 2.5rem;

    margin: 6px 15px 0;

    i {
      font-size: 2rem;
    }

    &:hover {
      cursor: pointer;

      opacity: .7;
    }

    &::after {
      display: block;

      font-size: .7rem;

      position: relative;
      top: -10px;
    }

    &.download {
      &::after {
        content: 'Download';
      }
    }

    &.mobile {
      &::after {
        content: 'Mobil';
      }
    }

    &.tablet {
      &.landscape {
        i {
          transform: rotate(90deg);
        }
      }

      &::after {
        content: 'Tablet';
      }
    }

    &.desktop {
      &::after {
        content: 'Desktop';
      }
    }
  }

  .active {
    color: $color-grey;
  }
}

.iframe-pos iframe {
  display: block;

  width: 100%;
  height: 100vh;
  margin: 0 auto;

  transition: all 300ms;

  border: 0;

  @media ($medium) {
    height: 100%;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.iframe-pos {
  @media ($medium) {
    position: absolute;
    z-index: 1;
    top: 170px;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

/*
██████  ██████  ██  ██████ ███████ ██████   ██████  ██   ██
██   ██ ██   ██ ██ ██      ██      ██   ██ ██    ██  ██ ██
██████  ██████  ██ ██      █████   ██████  ██    ██   ███
██      ██   ██ ██ ██      ██      ██   ██ ██    ██  ██ ██
██      ██   ██ ██  ██████ ███████ ██████   ██████  ██   ██
*/


.pricebox {
  display: inline-block;

  margin: 0 auto;
  padding: 40px 60px 50px;

  position: relative;

  background: $color-white-95;

  &::before,
  &::after {
    width: 50%;
    max-width: 300px;

    position: absolute;
    z-index: -1;
    top: 80%;
    bottom: 15px;
    left: 10px;

    content: '';
    -webkit-transform: rotate(-3deg);
       -moz-transform: rotate(-3deg);
        -ms-transform: rotate(-3deg);
         -o-transform: rotate(-3deg);
            transform: rotate(-3deg);

    background: $color-grey;
    -webkit-box-shadow: 0 15px 10px $color-grey;
       -moz-box-shadow: 0 15px 10px $color-grey;
            box-shadow: 0 15px 10px $color-grey;
  }
  &::after {
    right: 10px;
    left: auto;

    -webkit-transform: rotate(3deg);
       -moz-transform: rotate(3deg);
        -ms-transform: rotate(3deg);
         -o-transform: rotate(3deg);
            transform: rotate(3deg);
  }
  p {
    line-height: 1.5;

    margin-bottom: 0;
    span {
      display: block;

      font-size: 2.5rem;
      font-weight: 700;

      margin-bottom: 5px;

      letter-spacing: -.1rem;
    }
  }
}

/*
 █████  ██████  ██████
██   ██ ██   ██ ██   ██
███████ ██████  ██████
██   ██ ██      ██
██   ██ ██      ██
*/



#app {
  padding: 40px 0 15px;

  text-align: left;
}

.content,
#app {
  margin-top: 66px;

  @media ($medium) {
    margin-top: 80px;
  }
}

.content #app {
  margin-top: 0;
  // display: flex;
  // flex-direction: column;
  //
  // width: 100%;
  // height: 100%;
  // margin: 0;
  // padding: 0;
  //
  // align-items: center;
  // justify-content: center;
}


.g-recaptcha {
  position: relative;
}

/*
████████ ███████ ███    ███ ██████  ██       █████  ████████ ███████
   ██    ██      ████  ████ ██   ██ ██      ██   ██    ██    ██
   ██    █████   ██ ████ ██ ██████  ██      ███████    ██    █████
   ██    ██      ██  ██  ██ ██      ██      ██   ██    ██    ██
   ██    ███████ ██      ██ ██      ███████ ██   ██    ██    ███████
*/



#template {
  overflow: hidden;

  width: 100%;
  height: 100%;
  padding: 1em;

  border: 1px solid #d3e0e9;
  border-radius: 4px;
  background: #fff;

  // resets
  * {
    border-radius: 0;
  }

}


// App
.app {
  font-family: 'Raleway', sans-serif;

  height: 100vh;
  margin: 0;

  color: #636b6f;
  background-color: #fff;

  &__welcome {
    font-weight: 100;

    .full-height {
      height: 100vh;
    }

    .flex-center {
      display: flex;

      align-items: center;
      justify-content: center;
    }

    .position-ref {
      position: relative;
    }

    .top-right {
      position: absolute;
      top: 18px;
      right: 10px;
    }

    .content {
      text-align: center;
    }

    .title {
      font-size: 84px;
    }

    .title span {
      display: block;
    }

    .links > a,
    .title span {
      font-size: 12px;
      font-weight: 600;

      padding: 0 25px;

      text-decoration: none;
      letter-spacing: .1rem;
      text-transform: uppercase;

      color: #636b6f;
    }

    .m-b-md {
      margin-bottom: 30px;
    }
  }

  &__register,
  &__edit {
    textarea {
      font-family: monospace;
    }
  }

  &__dashboard {
    &--preview {
      font-family: monospace;

      margin-top: 1rem;
    }
  }

  &__item {
    &--container {
      textarea {
        font-family: monospace;
      }

      input.images {
        margin-bottom: 15px;
      }
    }
  }
}
