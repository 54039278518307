
// Body
$body-bg: #f5f8fa;

$brand-color: #9c0;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097d1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$font-family-sans-serif: 'Raleway', sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #464e5d;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

// Query sizes
$small: 'min-width: 544px';
$medium: 'min-width: 768px';
$large: 'min-width: 992px';
$xlarge: 'min-width: 1200px';
$xxlarge: 'min-width: 1400px';


// client specific
$brand-color: #9acb0e;
$brand-color-text: #333;
$brand-color-link: $brand-color;
$brand-color-link-hover: lighten($brand-color, 20);

$brand-background-color: $brand-color;

$dark-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
$light-shadow: 0 1px 2px rgba(0,0,0,.1);
$color-white: #fff;
$color-black: #000;
$color-red: #e56666;
$color-blue: #8c9dae;

$color-darkgrey: lighten($color-black, 20);
$color-mediumgrey: lighten($color-black, 40);
$color-grey: lighten($color-black, 60);
$color-lightgrey: lighten($color-black, 80);
$color-palegrey: lighten($color-black, 90);
$color-white-95: lighten($color-black, 95);

$color-black-85: rgba($color-black, .85);
